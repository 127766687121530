<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseTextField,
	BaseTextArea,
	getTextFieldBinds,
	BaseFetchWrapper, useAsyncState,
} from '@legacy-studio/library';
import { type HelpForm } from '../types';
import { helpSchema } from '../core/validation';
import { UiDarkBBButton } from '~/src/shared/ui';
import { useSendMessagePartner } from '~/src/features/partners-support/applictation';
import { closeSupportModal } from '~/src/features/partners-support';

withDefaults(defineProps<{
	isFullWidth?: boolean;
}>(), {
	isFullWidth: false,
});

const { defineField, handleSubmit, resetForm } = useForm<HelpForm>({
	validationSchema: helpSchema,
	initialValues: {
		name: '',
		email: '',
		message: '',
	},
});

const [name, nameProps] = defineField('name', getTextFieldBinds);
const [email, emailProps] = defineField('email', getTextFieldBinds);
const [message, messageProps] = defineField('message', getTextFieldBinds);

const { execute } = useAsyncState(useSendMessagePartner());

const pending = ref(false);

const onSubmit = handleSubmit(async (values) => {
	await execute(values);
	closeSupportModal();
	resetForm();
});
</script>

<template>
	<BaseFetchWrapper :pending="pending">
		<form
			id="helpFrom"
			class="flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<BaseTextField
				v-model="name"
				v-bind="nameProps"
				label="Имя"
				size="regular"
				placeholder="Введите имя"
			/>

			<BaseTextField
				v-model="email"
				v-bind="emailProps"
				label="Ваш email"
				size="regular"
				placeholder="Введите email"
			/>

			<BaseTextArea
				v-model="message"
				v-bind="messageProps"
				label="Текст сообщения"
				size="regular"
			/>

			<UiDarkBBButton
				size="sm"
				type="submit"
				button-text="Отправить"
				:class="[isFullWidth ? 'w-full' : 'w-fit']"
				form="helpFrom"
			/>
		</form>
	</BaseFetchWrapper>
</template>
