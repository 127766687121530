import { apiMethods } from '../infrastructure/api/index';

export const useSendMessagePartner = () => {
	const getSendMessagePartner = apiMethods.useSendMessagePartner();

	return async (payload: any) => {
		const result = await getSendMessagePartner(payload);
		if (result.isRight()) {
			console.log(result.value);
		}
	};
};
